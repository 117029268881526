'use client';

import React, { useState, useEffect } from 'react';

import Image, { StaticImageData } from 'next/image';

import { motion } from 'framer-motion';

import { ChevronLeft } from 'lucide-react';

import WebView from '@/features/auth/components/WebView';
import { messages } from '@/features/auth/localization';

import FormRegistration from '@/features/auth/shared/FormRegistration';
import BonusSelection from '@/features/Bonus/BonusSelection';
import { core } from '@/localization';

import { useAuthStore } from '@/stores/userStore';
import { userUIStore } from '@/stores/userUIStore';

import {
  IconBall,
  IconDice,
  bonoCasino,
  bonoSport,
  bonoCasinoMovil,
  bonoSportMovil,
  defaultBanner,
  defaultBannerMovil,
} from '@/public/img/marketing/promotions';

import { Button } from '../ui/button';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogTrigger,
} from '../ui/dialog';
import { ScrollArea } from '../ui/scroll-area';

interface Props {
  isWebView: boolean;
  isDefaultOpen: boolean;
}

const diceVariants = {
  initial: { rotate: 0 },
  animate: {
    rotate: [0, 360, 720, 1080, 1440, 1800, 1440, 1080, 720, 360, 0],
    transition: {
      duration: 9,
      ease: [0.83, 0, 0.17, 1],
      repeat: Infinity,
      repeatType: 'loop' as const,
    },
  },
};

const footballVariants = {
  initial: { y: 0 },
  animate: {
    y: [0, -7, 10],
    transition: {
      duration: 1,
      repeat: Infinity,
      repeatType: 'loop' as const,
    },
  },
};

const RegisterModal = ({ isDefaultOpen, isWebView }: Props) => {
  const [isMobile, setIsMobile] = useState(false);
  const [isMobileForm, setIsMobileForm] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [welcomeBonus, setWelcomeBonus] = useState<string | null>(null);
  const [bonusSelection, setBonusSelection] = useState<boolean>(false);

  const { setTypeBonusSelected } = useAuthStore();
  const [imageToShow, setImageBanner] = useState<StaticImageData>(
    isMobile ? defaultBannerMovil : defaultBanner,
  );

  const handleBonusSelection = () => {
    setBonusSelection(true);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Consider mobile if width <= 768px
    };

    handleResize(); // Initialize on first render
    window.addEventListener('resize', handleResize);

    // Cleanup event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const imageBanner: Record<string, StaticImageData> = {
      casino: isMobile ? bonoCasinoMovil : bonoCasino,
      sports: isMobile ? bonoSportMovil : bonoSport,
      none: isMobile ? defaultBannerMovil : defaultBanner,
      null: isMobile ? defaultBannerMovil : defaultBanner,
    };
    setImageBanner(imageBanner[welcomeBonus || 'none' || 'null']);
    if (welcomeBonus && welcomeBonus !== '') setTypeBonusSelected(welcomeBonus);
  }, [welcomeBonus, setTypeBonusSelected, isMobile]);

  const {
    isRegisterModalOpen,
    toggleRegisterModal,
    toggleLoginModal,

  } = userUIStore();

  return (
    <Dialog
      modal={false}
      defaultOpen={isDefaultOpen}
      open={isRegisterModalOpen}
      onOpenChange={toggleRegisterModal}
    >
      {!isDefaultOpen && (
        <DialogTrigger asChild>
          <Button className="primary-bg-btn text-sivarbet-background font-semibold" size="sm" variant="secondary">
            {messages.register}
          </Button>
        </DialogTrigger>
      )}
      <DialogContent
        onOpenAutoFocus={(e) => {
          return e.preventDefault();
        }}
        onInteractOutside={(e) => {
          e.preventDefault();
        }}
        className="bg-sivarbet-secondary-bg border-none p-0 flex flex-col gap-0 lg:flex-row lg:flex-nowrap lg:max-w-[816px] lg:max-h-[630px] h-full bg-gradient-to-br from-[#29ABE2]/20 to-black rounded-lg"
      >
        <div
          className={`relative ${isMobile ? 'w-full h-[200px] aspect-[9/16]' : 'w-1/2 aspect-[16/9]'} overflow-hidden`}
        >
          <Image
            src={imageToShow}
            alt="register"
            objectFit="contain"
            objectPosition="center"
            sizes="(max-width: 768px) 100vw, 50vw"
            priority
          />
        </div>
        {!bonusSelection ? (
          <BonusSelection
            welcomeBonus={welcomeBonus || ''}
            setWelcomeBonus={setWelcomeBonus}
            handleBonusSelection={handleBonusSelection}
          />
        ) : (
          <ScrollArea className="lg:w-[50%] flex flex-col justify-center h-full ov pb-5">
            {isWebView ? (
              <WebView />
            ) : (
              <>
                {(welcomeBonus === 'casino' || welcomeBonus === 'sports') && (
                  <div className="flex items-center justify-between w-full bg-sivarbet-primary p-3 lg:mt-12">
                    <div className="text-sivarbet-border flex items-center gap-2">
                      <ChevronLeft
                        className="text-sivarbet-border cursor-pointer hover:text-sivarbet-background"
                        onClick={() => {
                          setBonusSelection(false);
                          setWelcomeBonus('');
                        }}
                      />
                      <span>
                        {welcomeBonus === 'casino' ? 'Casino: 100 Giros Gratis' : 'Deportes: 100 HNL Gratis'}
                      </span>
                    </div>
                    <div className="flex items-center space-x-2">
                      <motion.div
                        variants={
                          welcomeBonus === 'casino' ? diceVariants : footballVariants
                        }
                        initial="initial"
                        animate="animate"
                      >
                        <Image
                          src={welcomeBonus === 'casino' ? IconDice : IconBall}
                          alt="IconBall"
                          width={20}
                          height={20}
                        />
                      </motion.div>
                    </div>
                  </div>
                )}
                <DialogTitle className="flex justify-start relative text-inherit leading-[20px] font-medium font-inherit mx-[17px] mb-8 mt-2">
                  {`${messages.registerTo} ${core.appName}`}
                </DialogTitle>
                <div className="h-[11px] relative tracking-[-0.01em] leading-[20px] inline-block mx-[17px] max-lg:hidden mb-6">
                  <span className="mr-2">{messages.alreadyHaveAnAccount}</span>
                  <span
                    onClick={() => {
                      toggleRegisterModal();
                      toggleLoginModal();
                    }}
                    className="[text-decoration:underline] text-sivarbet-primary cursor-pointer"
                  >
                    {messages.enterHere}
                  </span>
                </div>

                <FormRegistration
                  isMobileForm={isMobileForm}
                  setIsMobileForm={setIsMobileForm}
                  loading={loading}
                  setLoading={setLoading}
                  welcomeBonus={welcomeBonus || ''}
                  parent=""
                  clickId=""
                  btag=""
                />
              </>
            )}
          </ScrollArea>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default RegisterModal;
