import { transactions, users } from '@prisma/client';

import { Currency, TransactionTypes, TransactionStates } from '@/enums/core';

import {
  createOrUpdateReferral,
  exception, findUserByIdRecharge,
  getReferrals,
} from './core';

import { prisma } from './prisma';
import { referralsRules } from './rules';

class Referral {
  private user: users & { profiles: { full_profile: boolean } };

  constructor(user: users & { profiles: { full_profile: boolean } }) {
    this.user = user;
  }

  async isDoneCommission(): Promise<boolean> {
    const commission = await prisma.transactions.findFirst({
      where: {
        user_id: this.user.id,
        type: TransactionTypes.COMMISSION,
        status: { notIn: [TransactionStates.CANCELLED, TransactionStates.REJECTED] },
      },
    });
    return !!commission;
  }

  async validateReferrals(amount: number): Promise<boolean> {
    if (!this.user?.affiliator_id) {
      return false;
    }

    const { kyc_level: kycLevel, profiles } = this.user;
    const { kyc, minDeposit, fullProfile } = referralsRules;
    const isDoneCommission = await this.isDoneCommission();

    if (kycLevel === kyc && amount >= minDeposit && profiles?.full_profile === fullProfile && !isDoneCommission) {
      return true;
    }

    return false;
  }

  async createCommission(transaction: transactions): Promise<{ success: boolean; message: string }> {
    try {
      if (!this.user?.affiliator_id) {
        return { success: false, message: 'Usuario referido no encontrado' };
      }

      const mainUser = await findUserByIdRecharge(this.user.affiliator_id);
      // eslint-disable-next-line no-console
      console.log('💸 ~ createCommission ~ transaction:', transaction, 'user ==> ', this.user, 'mainUser ==> ', mainUser);
      if (!mainUser || !mainUser.referreds_count) {
        return { success: false, message: 'Usuario principal o referidos no encontrados' };
      }

      const referralRule = referralsRules.commissions.find(
        (rule) => { return mainUser.referreds_count > rule.minReferral && mainUser.referreds_count <= rule.maxReferral; },
      );

      if (!referralRule) {
        return { success: false, message: 'No hay regla de comisión para el usuario referido' };
      }

      const amount = referralRule.type === 'int' ? referralRule.comission : (transaction.amount ?? 0) * referralRule.comission;

      const [comissionTransaction, referralUpdate] = await prisma.$transaction([
        prisma.transactions.create({
          data: {
            user_id: mainUser.id,
            status: TransactionStates.PENDING,
            type: TransactionTypes.COMMISSION,
            amount,
            currency: Currency.HNL,
            gateway: transaction.gateway,
            description: 'Comisión por referido',
            id_txn: transaction.id.toString(),
            bonus_percentage: referralRule.comission,
          },
        }),
        prisma.referrals.update({
          where: { id: mainUser.referralsId },
          data: { pending_amount: { increment: amount } },
        }),
      ]);

      if (comissionTransaction && referralUpdate) {
        return { success: true, message: 'Comisión creada' };
      }

      return { success: false, message: 'Error al crear comisión' };
    } catch (error) {
      await exception(error, { route: '/utils/referrals.ts: createCommission', method: 'DB', req: { transaction } });
      return { success: false, message: 'Error al crear comisión' };
    }
  }

  async getReferralsList(): Promise<any> {
    const referralsList = await getReferrals(this.user.topup_id || '');
    if (!referralsList) {
      return [];
    }
    return referralsList;
    // eslint-disable-next-line no-console
    console.log('refferals', this);
  }

  async addNewReferred(topoutid:string): Promise<any> {
    const result = await createOrUpdateReferral(topoutid);
    return result;
    // eslint-disable-next-line no-console
    console.log('refferals', this);
  }
}

export default Referral;
